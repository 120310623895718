import React, { useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import AuthContext from './AuthContext.js'
import './App.css';
import { pricingData } from "./pricingData.js";

const PricingPage = () => {
    const navigate = useNavigate();
    const { user, loading } = useContext(AuthContext);

    return (
        <div className='pricing-page'>
            <main className='container'>
                <div className='comp text-center my-4'>
                    <h1 className='comp-name'>Pricing</h1>
                    <p className='comp-descrip'>Clear, Transparent Pricing</p>
                </div>
                <div className='main-section mb-4'>
                    <p>San Novus Trademark values a transparent and simple pricing structure. We offer fixed prices for our services,
                        billing by project rather than on an hourly basis. This approach ensures that you know exactly what to expect in terms of costs,
                        without any surprises.</p>
                </div>
                <div className='price-table'>
                    <div className='d-flex justify-content-between p-2 font-weight-bold bg-light'>
                        <div className='service-name' style={{ flexBasis: '50%' }}>Service</div>
                        <div className='service-price text-center' style={{ flexBasis: '25%' }}>Attorney Fee</div>
                        <div className='service-price text-center' style={{ flexBasis: '25%' }}>Government Fee<br />(per class)</div>
                    </div>
                    {pricingData.map((category, index) => (
                        <div key={`category-${index}`} className='mb-4'>
                            {category.category && (
                                <div className='category-title font-weight-bold bg-primary text-white p-2'>{category.category}</div>
                            )}
                            <div className='d-flex flex-column'>
                                {category.services.map((service, serviceIndex) => (
                                    <div key={`service-${index}-${serviceIndex}`} className='d-flex justify-content-between p-2 service-row'>
                                        {/* <div className='service-name'>{service.service}</div>
                                        <div className='service-price text-center'>{service.price}</div> */}
                                        <div className='service-name' style={{ flexBasis: '50%' }}>{service.service}</div>
                                        <div className='service-price text-center' style={{ flexBasis: '25%' }}>
                                            <span>{service.attyFee}</span>
                                        </div>
                                        <div className='service-price text-center' style={{ flexBasis: '25%' }} dangerouslySetInnerHTML={{ __html: service.govtFee }} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className='main-section'>
                    <p>Please note: Government filing fees are set by the U.S.
                        Patent and Trademark Office and are subject to change. For the most current fee schedule, please visit 
                        the <a href='https://www.uspto.gov/learning-and-resources/fees-and-payment/uspto-fee-schedule#Trademark%20Fees' target="_blank" rel="noopener noreferrer" className='text-primary'>
                            USPTO Fee Schedule</a>.</p>
                </div>
            </main>
        </div>
    );

};

export default PricingPage;