import React, { useState, useEffect, useMemo, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import UseSelection from "./useBox.js";
import { pricingData } from "./pricingData.js";

const parseRejectionString = (description) => {
    // Split into rejection type and class details
    const [type, ...classDetails] = description.split(/\s\{/); // Split by " {" while preserving the rejection type

    // Normalize the type
    const normalizedType = type.trim();

    // Extract class details
    const classInfo = classDetails
        .map((detail) => {
            const match = detail.match(/^class (\d+):\s(.*)\}$/); // Match "class xxx: details}"
            return match
                ? {
                    classNumber: match[1].padStart(3, "0"), // Class number with leading zeros
                    description: match[2].trim(), // Class description
                }
                : null;
        })
        .filter(Boolean); // Remove null entries

    return { type: normalizedType, classInfo };
};

const OfficeActionResponseSubModal = ({
    selectedFile,
    selectedDeadline,
    onFormSubmit,
    onClose,
}) => {
    const { summary, description, start_at } = selectedDeadline;
    const [expandedRejectionIndex, setExpandedRejectionIndex] = useState(null);
    const [classBlocks, setClassBlocks] = useState([]);
    const [selectedUse, setSelectedUse] = useState({});
    const [filePreviews, setFilePreviews] = useState([]);
    const filesMap = useRef({});
    const [totalAttyFee, setTotalAttyFee] = useState(0);
    const [totalGovtFee, setTotalGovtFee] = useState(0);
    const [classDescriptions, setClassDescriptions] = useState({});
    const [gsBlocks, setGsBlocks] = useState({});

    const parsedRejections = useMemo(() => {
        if (!description) return [];
        console.log('selectedEvent description: ', description);
        // Split the description by "|" to handle multiple rejections
        return description.split("|").map(parseRejectionString);

    }, [description]);

    useEffect(() => {
        console.log("Rendering file previews:", filePreviews);
        console.log("Current classBlocks:", classBlocks);
    }, [filePreviews, classBlocks]);



    // useEffect for 'audit' rejection
    useEffect(() => {
        const auditRejection = parsedRejections.find((rejection) => rejection.type === "Audit");
        console.log("Audit Rejection:", auditRejection);

        if (auditRejection) {
            const blocks = auditRejection.classInfo.map((info) => ({
                id: info.classNumber, // Ensure `id` matches `blockId`
                className: info,
                url: "",
                specimens: [],
            }));
            console.log("Populating classBlocks for 'audit':", blocks);
            setClassBlocks(blocks);
        } else {
            console.log("No audit rejection found in parsedRejections:", parsedRejections);
        }
    }, [parsedRejections]);


    useEffect(() => {
        console.log("Updated classBlocks:", classBlocks);
    }, [classBlocks]);


    // useEffect for 'identification of goods & services' rejection
    useEffect(() => {
        const identificationRejection = parsedRejections.find(
            (rejection) =>
                rejection.type.toLowerCase() === "identification of goods & services".toLowerCase()
        );

        console.log("Identification Rejection:", identificationRejection);

        if (identificationRejection) {
            const blocks = selectedFile.custom_field_values.reduce((result, field) => {
                const classMatch = field.field_name.match(/^Class (\d+)$/);
                if (classMatch) {
                    const index = classMatch[1];
                    const descriptionField = selectedFile.custom_field_values.find(
                        (descField) => descField.field_name === `Description ${index}`
                    );

                    result.push({
                        classNumber: field.value.padStart(3, "0"), // Class number with leading zeros
                        description: descriptionField ? descriptionField.value : "", // Class description
                    });
                }
                return result;
            }, []);

            console.log("Populating gsBlocks for 'Identification of Goods & Services':", blocks);
            setGsBlocks(blocks);
        }
    }, [parsedRejections, selectedFile.custom_field_values]);


    useEffect(() => {
        calculateCosts();
    }, [classBlocks]);

    const handleGsDescriptionChange = (classNumber, newValue) => {
        setClassDescriptions((prev) => ({ ...prev, [classNumber]: newValue }));
    };

    const rejectionTypeToPricingMap = {
        "Identification of Goods & Services": "Objection to Description of Goods/Services",
        "Audit": "Audit",
        "Disclaimer": "Disclaimer",
        "Likelihood of Confusion": "Likelihood of Confusion",
        // Add other mappings as needed
    };


    const calculateCosts = () => {
        let totalAtty = 0;
        let totalGovt = 0;

        parsedRejections.forEach(({ type }) => {
            const mappedType = rejectionTypeToPricingMap[type] || type; // Map the type if it exists
            const pricingCategory = pricingData.find(
                (category) => category.category === "Trademark Office Action"
            );
            const pricing = pricingCategory?.services.find(
                (service) => service.service.toLowerCase() === mappedType.toLowerCase()
            );

            if (pricing) {
                const attyFee = parseFloat(pricing.attyFee.replace("$", "").replace(",", ""));
                const govtFee = parseFloat(pricing.govtFee.replace("$", "").replace(",", "")) || 0;

                totalAtty += attyFee;
                totalGovt += govtFee;
            }
        });

        setTotalAttyFee(totalAtty);
        setTotalGovtFee(totalGovt);
    };


    const totalCost = totalAttyFee + totalGovtFee;

    const rejectionDescriptions = {
        "Likelihood of Confusion": "This refusal occurs when the USPTO believes that the applied-for trademark is too similar to a registered mark in appearance, sound, meaning, or commercial impression.",
        "Disclaimer": "A disclaimer is required when a portion of the applied-for mark is not inherently distinctive and must be disclaimed as it cannot be exclusively owned.",
        "Merely Descriptive": "This refusal occurs when the applied-for mark merely describes an ingredient, quality, characteristic, function, feature, purpose, or use of the specified goods or services.",
        "Geographically Descriptive": "This refusal occurs when the mark is primarily geographically descriptive of the origin of the goods or services.",
        "audit": "An audit requires submission of additional specimens for specific classes.",
        "Translation": "The USPTO requires a translation of the non-English terms in the applied-for mark.",
        "Specimen Refusal": "The submitted specimen does not show the applied-for mark as actually used in commerce.",
        "Identification of Goods & Services": "This rejection requires clarification or amendment of the goods/services listed under specific classes.",
        // Add more rejection types as needed
    };

    const handleUseSelectionChange = (blockId, value) => {
        setSelectedUse((prevUse) => ({ ...prevUse, [blockId]: value }));

        setClassBlocks((prevBlocks) =>
            prevBlocks.map((block) => {
                if (block.id !== blockId) return block;

                const updatedBlock = { ...block };
                if (value === "web") {
                    updatedBlock.specimens = [];
                    setFilePreviews((prevPreviews) =>
                        prevPreviews.filter((preview) => preview.blockId !== blockId)
                    );
                } else if (value === "use") {
                    updatedBlock.url = "";
                }
                return updatedBlock;
            })
        );
    };

    const handleInputChange = (blockId, field, value) => {
        setClassBlocks((prevBlocks) =>
            prevBlocks.map((block) =>
                block.id === blockId ? { ...block, [field]: value } : block
            )
        );
    };

    const handleSpecimenFileChange = (blockId, files) => {
        const fileArray = Array.from(files);
        const updatedFilePreviews = [...filePreviews];

        const block = classBlocks.find((block) => block.id === blockId);
        if (!block) {
            console.error("Block not found for blockId:", blockId);
            return;
        }

        const classNumber = block.id.padStart(3, "0");

        fileArray.forEach((file, index) => {
            const specimenNumber = (block.specimens?.length || 0) + index + 1;
            const standardizedFileName = `Class ${classNumber} - Specimen ${specimenNumber}.${file.name.split('.').pop()}`;

            const fileId = `${standardizedFileName}-${Date.now()}`;

            // Check if the file already exists
            if (updatedFilePreviews.some((preview) => preview.name === standardizedFileName)) {
                console.warn(`Duplicate file detected: ${standardizedFileName}`);
                return;
            }

            const newFile = new File([file], standardizedFileName, { type: file.type });

            filesMap.current[fileId] = newFile;

            const reader = new FileReader();

            reader.onloadend = () => {
                const fileInfo = {
                    id: fileId,
                    blockId,
                    name: standardizedFileName,
                    url: reader.result,
                };

                updatedFilePreviews.push(fileInfo);
                setFilePreviews([...updatedFilePreviews]);

                setClassBlocks((prevBlocks) =>
                    prevBlocks.map((block) =>
                        block.id === blockId
                            ? { ...block, specimens: [...(block.specimens || []), fileInfo] }
                            : block
                    )
                );
            };

            reader.readAsDataURL(newFile);
        });
    };



    const removeFilePreview = (fileId) => {
        setFilePreviews((prevPreviews) => prevPreviews.filter((preview) => preview.id !== fileId));
        setClassBlocks((prevBlocks) =>
            prevBlocks.map((block) => ({
                ...block,
                specimens: block.specimens.filter((specimen) => specimen.id !== fileId),
            }))
        );
    };

    return (
        <Modal show onHide={onClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Authorize Office Action Response</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <h5>Office Action Details</h5>
                    <p>
                        <strong>Summary:</strong> {summary || "N/A"}
                    </p>
                    <p>
                        <strong>Deadline:</strong> {new Date(start_at).toLocaleDateString() || "N/A"}
                    </p>
                </div>
                <hr />
                <div>
                    <h5>Rejection Types in this Office Action</h5>
                    {parsedRejections.map(({ type, classInfo }, index) => (
                        <div key={index} className="accordion-item authorizeSOU-classBlock-header">
                            <div
                                className="accordion-header"
                                onClick={() =>
                                    setExpandedRejectionIndex(
                                        expandedRejectionIndex === index ? null : index
                                    )
                                }
                            >
                                <h2>{type}</h2>
                            </div>
                            {expandedRejectionIndex === index && (
                                <div className="accordion-content authorizeSOU-classBlock-content">
                                    <p>
                                        {rejectionDescriptions[type.trim()] ||
                                            ""}
                                    </p>
                                    {type.toLowerCase() === "identification of goods & services" &&
                                        gsBlocks.map((block, idx) => (
                                            <div key={idx} className="class-block">
                                                <label>
                                                    <strong>Class {block.classNumber}:</strong>
                                                </label>
                                                <textarea
                                                    className="form-control"
                                                    rows="3"
                                                    value={
                                                        classDescriptions[block.classNumber] ||
                                                        block.description
                                                    }
                                                    onChange={(e) =>
                                                        handleGsDescriptionChange(
                                                            block.classNumber,
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        ))}
                                    {type.toLowerCase() === "audit" &&
                                        classInfo.map((info, idx) => (
                                            <div key={idx} className="class-block">
                                                <h4>Class {info.classNumber}</h4>
                                                <p>{info.description}</p>
                                                <UseSelection
                                                    value={selectedUse[info.classNumber]}
                                                    onChange={(value) =>
                                                        handleUseSelectionChange(info.classNumber, value)
                                                    }
                                                    excludeOptions={["itu", "foreignApp", "foreignReg"]}
                                                />

                                                {selectedUse[info.classNumber] === "web" && (
                                                    <div>
                                                        <label>Enter URL:</label>
                                                        <input
                                                            type="text"
                                                            value={info.url || ""}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    info.classNumber,
                                                                    "url",
                                                                    e.target.value
                                                                )
                                                            }
                                                            className="form-control"
                                                        />
                                                    </div>
                                                )}

                                                {selectedUse[info.classNumber] === "use" && (
                                                    <div>
                                                        <label>Upload Specimens:</label>
                                                        <input
                                                            type="file"
                                                            multiple
                                                            onChange={(e) =>
                                                                handleSpecimenFileChange(
                                                                    info.classNumber,
                                                                    e.target.files
                                                                )
                                                            }
                                                        />
                                                        <div className="file-previews">
                                                            {console.log("Rendering file previews for blockId:", info.classNumber)}
                                                            {console.log("Current filePreviews:", filePreviews)}
                                                            {filePreviews
                                                                .filter((preview) => preview.blockId === info.classNumber)
                                                                .map((preview) => (
                                                                    <div key={preview.id} className="file-preview">
                                                                        <img
                                                                            src={preview.url}
                                                                            alt={preview.name}
                                                                            className="img-thumbnail"
                                                                            style={{
                                                                                width: "100px",
                                                                                height: "100px",
                                                                                objectFit: "cover",
                                                                                margin: "10px",
                                                                            }}
                                                                        />
                                                                        <p>{preview.name}</p>
                                                                        <Button
                                                                            variant="danger"
                                                                            size="sm"
                                                                            onClick={() => removeFilePreview(preview.id)}
                                                                        >
                                                                            Remove
                                                                        </Button>
                                                                    </div>
                                                                ))}
                                                        </div>

                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                {/* Cost Summary */}
                <div className="cost-summary">
                    <h3>Cost Summary</h3>
                    <p>Attorney Fee: ${totalAttyFee.toFixed(0)}</p>
                    <p>Government Fee: ${totalGovtFee.toFixed(0)}</p>
                    <h4>Total Cost: ${totalCost.toFixed(0)}</h4>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    onClick={() => onFormSubmit({ classBlocks, filePreviews })}
                >
                    Authorize Response
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default OfficeActionResponseSubModal;
