import React, { useState, useEffect } from 'react';
import { pricingData } from './pricingData.js';

const RegReview = ({
    formData,
    setFormData,
    estimatedCost,
    setEstimatedCost,
    }) => {

    const [showSearchModal, setShowSearchModal] = useState(false);
    const [appSearchNo, setAppSearchNo] = useState('');
    const [loading, setLoading] = useState(false);
    const [docInfo, setDocInfo] = useState('');
    const [totalCost, setTotalCost] = useState(0);
    const filingCosts = {
        "Section 8 only": {atty: 150, govt: 225},
        "Section 8 surcharge": {atty: 0, govt: 100},
        "Section 8 & 15": {atty: 200, govt: 425},
        "Section 9 only": {atty: 150, govt: 300},
        "Section 9 surcharge": {atty: 0, govt:100},
        "Section 8 & 9": {atty: 200, cost: 525},
    };

    useEffect(() => {
        if (formData.nextDeadlineType) {
            calculateTotalCost();
        }
    }, [formData]);

    // Helper function to find service in pricing data
    const findServiceCost = (serviceType) => {
        const renewalsCategory = pricingData.find(category => category.category === "Renewals");
        if (renewalsCategory) {
            const service = renewalsCategory.services.find(s => s.service.toLowerCase().includes(serviceType.toLowerCase()));
            if (service) {
                // Split the price string to extract attorney and government fees
                const [attyFee, govtFee] = service.price.match(/\d+/g).map(Number);
                return { atty: attyFee, govt: govtFee };
            }
        }
        return null;
    };
    
    // const calculateTotalCost = () => {
    //     const { nextDeadlineType, classBlocks } = formData;
    //     const numClasses = classBlocks.length;

    //     const baseCost = filingCosts[nextDeadlineType];
    //     const totalAttyCost = baseCost.atty * numClasses;
    //     const totalGovtCost = baseCost.govt * numClasses;

    //     const total = totalAttyCost + totalGovtCost;
    //     setTotalCost(total);
    //     setEstimatedCost(total);
    // };

    // const govtCostPerClass = filingCosts[formData.nextDeadlineType]?.govt;
    // const totalGovtCost = govtCostPerClass * formData.classBlocks.length;

    const calculateTotalCost = () => {
        const { nextDeadlineType, classBlocks } = formData;
        const numClasses = classBlocks.length;

        const baseCost = findServiceCost(nextDeadlineType); // Use the centralized pricing data
        if (baseCost) {
            const totalAttyCost = baseCost.atty;
            const totalGovtCost = baseCost.govt * numClasses;

            const total = totalAttyCost + totalGovtCost;
            setTotalCost(total);
            setEstimatedCost(total);
        }
    };

    const govtCostPerClass = findServiceCost(formData.nextDeadlineType)?.govt;
    const totalGovtCost = govtCostPerClass * formData.classBlocks.length;
    
    return (
        <div className='container'>
            <div className='oa-summary'>
                <div>
                    <div className='analysis-summary'>
                        <p>Estimate for Renewal Filing</p>
                        <table className='table table-striped table-responsive'>
                            <thead>
                                <tr>
                                    <th>Type of Filing</th>
                                    <th>Attorney Fee</th>
                                    <th>Government Fee</th>
                                    <th>Number of Classes</th>
                                    <th>Total Cost</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{formData.nextDeadlineType}</td>
                                    <td>${filingCosts[formData.nextDeadlineType]?.atty}</td>
                                    <td>${totalGovtCost} (${govtCostPerClass} per class)</td>
                                    <td>{formData.classBlocks.length}</td>
                                    <td>${totalCost}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RegReview;