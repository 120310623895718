// pricingData.js
export const pricingData = [
    { 
        category: "Trademark Searches", services: [
            {service: "Comprehensive Search", attyFee: "$529", govtFee: "$ -"},
        ]
    },
    { 
        category: "Trademark Applications", services: [
            { service: "Trademark Registration Application (without search)", attyFee: "$250", govtFee: "$250 (TEAS Plus)<br />OR<br />$350 (TEAS Standard)" },
            { service: "Trademark Registration Application (includes Knockout Search)", attyFee: "$375", govtFee: "$250 (TEAS Plus)<br />OR<br />$350 (TEAS Standard)" },
        ]
    },
    {
        category: "Trademark Office Action", services: [
            { service: "Likelihood of Confusion", attyFee: "$500", govtFee: "$ -" },
            { service: "Merely Descriptive", attyFee: "$500", govtFee: "$-" },
            { service: "Geographically Descriptive", attyFee: "$500", govtFee: "$ -" },
            { service: "Objection to Description of Goods/Services", attyFee: "$100", govtFee: "$350" },
            { service: "Rejection of Specimen", attyFee: "$175", govtFee: "$ -" },
            { service: "Objection to Mark Description", attyFee: "$100", govtFee: "$ -" },
            { service: "Primarily a Surname", attyFee: "$100", govtFee: "$ -" },
            { service: "Disclaimer", attyFee: "$100", govtFee: "$ -" },
            { service: "Translation", attyFee: "$100", govtFee: "$ -" },
            { service: "Audit", attyFee: "$350", govtFee: " - " },
        ]
    },
    { 
        category: "Trademark Statement of Use", services: [
            {service: "Statement of Use", attyFee: "$250", govtFee: "$100 (per class)"},
        ]
    },
    {
        category: "Renewals", services: [
            { service: "Section 8 only", attyFee: "$175", govtFee: "$225" },
            { service: "Section 8 (grace period)", attyFee: "$175", govtFee: "$325" },
            { service: "Section 15 only", attyFee: "$150", govtFee: "$200" },
            { service: "Section 9 only", attyFee: "$175", govtFee: "$300" },
            { service: "Section 9 (grace period)", attyFee: "$175", govtFee: "$400" },
            { service: "Section 8 & 15", attyFee: "$250", govtFee: "$425" },
            { service: "Section 8 & 15 (grace period)", attyFee: "$250", govtFee: "$525" },
            { service: "Section 8 & 9", attyFee: "$250", govtFee: "$525" },
            { service: "Section 8 & 9 (grace period)", attyFee: "$250", govtFee: "$625" },
        ]
    },
    {
        category: "International Applications", services: [
            { service: "Madrid Protocol", attyFee: "$375", govtFee: "Varies by country" },
            { service: "Direct Filing", attyFee: "$100 per country", govtFee: "Varies by country" }
        ]
    },
];

