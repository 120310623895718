import React, { useState, useEffect } from 'react';
import StepIndicator from './stepIndicator.js';
import ContactContact from './contactContact.js';
import OwnerContact from './ownerContact.js';
import { supabaseConflictCheck } from './supabase.js';
import ClioContactQuery from './clioContactQuery.js';
import { debounce, random } from 'lodash';
import SubmissionStatus from './submissionStatus.js';
import RegRetrival from './regRetrieval.js';
import OaReview from './oaReview.js';
import { json } from 'react-router-dom';
import * as pdfjsLib from 'pdfjs-dist/build/pdf.mjs';
import 'pdfjs-dist/build/pdf.worker.mjs';
import { Card } from 'react-bootstrap';
import CAR from './CAR.js';
import EngagementLetterRenewal from './engagementLetterRenewal.js';
import RenewalUseInfo from './renewalUseInfo.js';
import RegReview from './regReview.js';
import FormDownload, { generateFormContent } from './formDownload.js';
import { createClientEmail } from './createClientEmail.js';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;


const RenewTm = ({ toggleForm }) => {
  const initialFormData = {
    clioMatterID:'',
    contactID: '',
    contactFirstName:'',
    contactMiddleName:'',
    contactLastName:'',
    contactCompany:'',
    contactCompanyID:'',
    contactAddress1:'',
    contactAddress2:'',
    contactCity:'',
    contactState:'',
    contactZip:'',
    contactCountry:'',
    contactEmail:'',
    ownerID: '',
    ownerFirstName:'',
    ownerMiddleName:'',
    ownerLastName:'',
    ownerCompany:'',
    ownerCompanyID:'',
    ownerCompanyCountry:'',
    ownerCompanyType:'',
    ownerAddress1:'',
    ownerAddress2:'',
    ownerCity:'',
    ownerState:'',
    ownerZip:'',
    ownerCountry:'',
    ownerEmail:'',
    applicationType:'',
    wordMark: '',
    markLiteralElement:'',
    markColorClaim:'',
    designMarkFile:[],
    soundMarkDescription:'',
    soundMarkFile:[],
    classBlocks:[],
    potentialConflicts: [],
    version:0,
    // teasPlus:true,
  }
  const [formData, setFormData] = useState(initialFormData);
  const [currentStep, setCurrentStep] = useState(1);
  const [transferData, setTransferData] = useState(false);
  const [isTransferChecked, setIsTransferChecked] = useState(false);
  const [classBlocks, setClassBlocks] = useState([{id: 0, classValue: '' }])
  const [ownerType, setOwnerType] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [isCountryDisabled, setIsCountryDisabled] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [searchResults, setSearchResults] = useState('');
  const [loading, setLoading] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState ({
      conflictCheck: 'pending',
      createContactProfile: 'pending',
      createNewMatter: 'pending',
      uploadingDocuments: 'pending',
      submissionComplete: 'pending',
  });
  const [showSubmissionStatus, setShowSubmissionStatus] = useState(false);
  const [drawingURL, setDrawingURL] = useState('');
  const [certRegURL, setCertRegURL] = useState('');
  const [stepIsValid, setStepIsValid] = useState({});
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const [analyzingOa, setAnalyzingOa] = useState({
    isFound: false,
    isAnalyzing: false,
    isAnalyzed: false,
  });
  const [oaSummary, setOaSummary] = useState('');
  const [searchError, setSearchError] = useState(false);
  const [isEngagementLetterSigned, setIsEngagementLetterSigned] = useState(false);
  const [showEngagementLetter, setShowEngagementLetter] = useState(false);
  const [engagementContent, setEngagementContent] = useState('');
  const [carContent, setCarContent] = useState('');
  const [signatureText, setSignatureText] = useState('');
  const [signatureTime, setSignatureTime] = useState('');
  const [engagementLetterUrl, setEngagementLetterUrl] = useState('');
  const [estimatedCost, setEstimatedCost] = useState();
  const [validationError, setValidationError] = useState('');
  const [isCARFormSigned, setIsCARFormSigned] = useState(false);
  const [capturedFormData, setCapturedFormData] = useState('');

  const handleFormDataChange = (newData) => {
    setFormData(prevFormData => ({
      ...prevFormData, 
      ...newData,
    }));      
  };

//   Logging each time the formData is updated - for debugging purposes only
  useEffect(() => {
    // console.log('formData updated', formData);
  }, [formData]);

  useEffect (() => {
    if(selectedState) {
      setSelectedCountry("United States");
      setIsCountryDisabled(true);
    } else {
      setIsCountryDisabled(false);
    }
  }, [selectedState]);

  // const onUpdateClassBlocks = (blockId, fileInfo) => {
  //   setFormData(prevFormData => {
  //       const updatedClassBlocks = prevFormData.classBlocks.map(block => {
  //           if (block.id === blockId) {
  //               // Check if fileInfo is an array, if not make it an array
  //               const newFileInfoArray = Array.isArray(fileInfo) ? fileInfo : [fileInfo];
  //               // Add the new fileInfo to the specimens array of the matching block
  //               const updatedSpecimens = [...block.specimens, ...newFileInfoArray];
  //               return { ...block, specimens: updatedSpecimens };
  //           }
  //           return block;
  //       });

  //       return { ...prevFormData, classBlocks: updatedClassBlocks };
  //   });
  // };

  const onUpdateClassBlocks = (blockId, updateData) => {
    setFormData(prevFormData => {
        const updatedClassBlocks = prevFormData.classBlocks.map(block => {
            if (block.id === blockId) {
                // Check if updateData contains specimens or URL and update accordingly
                const updatedSpecimens = updateData.specimens 
                    ? [...block.specimens, ...updateData.specimens] 
                    : block.specimens;

                return { ...block, ...updateData, specimens: updatedSpecimens };
            }
            return block;
        });

        return { ...prevFormData, classBlocks: updatedClassBlocks };
    });
  };


  const onDeleteSpecimens = (blockId, updatedBlock) => {
    setFormData(prevFormData => ({
        ...prevFormData,
        classBlocks: prevFormData.classBlocks.map(block =>
            block.id === blockId ? updatedBlock : block
        ),
    }));
  };

  useEffect(() => {
    if (currentStep === 2 && formData.ownerEmail) {
      setValidationError('');
    } 
  }, [currentStep, formData.ownerEmail]);
  
  //Function to advance form step
  const nextStep = () => {
    // Set attemptedSubmit to true to indicate a submission attempt has been made
    // setAttemptedSubmit(true);

    // if(stepIsValid[currentStep]) {
    //   if (currentStep < 5) {
    //     setCurrentStep(currentStep + 1);
    //     // Set attemptedSubmit to false for next form step
    //     setAttemptedSubmit(false);
    //   }
    // }
    // if(currentStep === 2) {
    //   setCurrentStep(currentStep + 1);
    // }

    if(currentStep === 2 && !formData.ownerEmail) {
      setValidationError('Owner email is required');
      return;
    }

    // Clear any existing validation error if ownerEmail is provided
    setValidationError('');

    if (currentStep < 5) {
      setCurrentStep(currentStep + 1);
    }
  };

  //Function to decrease form step
  const previousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const hasSpecimenFiles = (formData) => {
    return formData.classBlocks.some(classBlock => classBlock.specimens.length > 0);
  };
  
  const hasUploadedDocuments = () => {
    const hasDesignOrSoundFiles = formData.designMarkFile.length > 0 || formData.soundMarkFile.length > 0;
    const hasSpecimens = hasSpecimenFiles(formData);
    return hasDesignOrSoundFiles || hasSpecimens;
  };

  const handleAgree = () => {
    setIsEngagementLetterSigned(true);
    setShowEngagementLetter(false);
    submitForm();  // Call submitForm again to proceed with the submission after the letter is signed
  };

  const submitForm = async () => {
    setAttemptedSubmit(true);

    if (!isCARFormSigned) {
      alert('Please sign the CAR form before submitting.');
      return;
    }
    // if(!stepIsValid[currentStep]) {
    //   return;
    // }

    if(!isEngagementLetterSigned) {
      setShowEngagementLetter(true);
      return;
    }

    //Display submission form
    setShowSubmissionStatus(true);

    const carFile = await generateCarPDF();

    // Provide a download link to the client
    const carPDFDownloadUrl = URL.createObjectURL(carFile);

    // Capture form content
    const formContent = generateFormContent(formData);
    setCapturedFormData(formContent);

    const formPDF = await generateFormPDF(formContent);

    // Provide a download link to the client
    const formContentDownloadURL = URL.createObjectURL(formPDF);

    //Set status for conflict check
    await setSubmissionStatus(prevStatus => ({ ...prevStatus, conflictCheck: 'inProcess'}));
    
    // Generate the PDF of the signed engagement letter
    const pdfFile = await generateEngagementPDF();

    // Add the PDF file to formData and proceed only after ensuring formData is updated
    await new Promise((resolve) => {
      setFormData(prevFormData => {
          const updatedFormData = { ...prevFormData, pdfFile };
          console.log('Updated formData w/ Engagement Letter: ', updatedFormData);
          resolve();
          return updatedFormData;
      });
    });  
    
    //Check for conflicts found during previous check
    if(formData.potentialConflicts.length > 0) {

      // await setSubmissionStatus(prevStatus => ({ ...prevStatus, conflictCheck: 'conflictFound'}));

    } else {
      //'Run' conflicts check
      const randomInterval = Math.floor(Math.random() * (6-2+1)) + 3;
      await wait(randomInterval * 1000);
      await setSubmissionStatus(prevStatus => ({ ...prevStatus, conflictCheck: 'complete'}));
    }

    // Provide a download link to the client
    const pdfDownloadUrl = URL.createObjectURL(pdfFile);
    setEngagementLetterUrl(pdfDownloadUrl);

    // Check conditions for creating a company
    let ownerCompanyID;
    let contactID;
    let contactCompanyID;
    let ownerID;
    let matterId;
    let specimensFolder;
    let USPTOFolder;
    let logosFolder;
    let renewalFolder;
    let carFolder;

    //Create contact company Clio contact if needed
    if(formData.contactCompany && !formData.contactCompanyID) {
      if(formData.potentialConflicts.length === 0) {
        await setSubmissionStatus(prevStatus => ({ ...prevStatus, createContactProfile: 'inProcess'}));
      }
      try {
        contactCompanyID = await createClioCompany();

        // Now call createClioPerson with the returned companyID if necessary
        if(!formData.contactID || formData.contactID === null) {
          contactID = await createClioPerson(contactCompanyID);
        }
      } catch (error) {
        console.error("Error in creating contacts:", error);
      }
    } else {
      // If company creation isn't needed, just create person if necessary
      if(!formData.contactID || formData.contactID === null) {
        contactID = await createClioPerson(contactCompanyID);
      } 
    }

    //Create owner company Clio contact if needed
    // if ((formData.ownerCompany !== '' || formData.ownerCompany !== null) && (formData.ownerCompanyID !== '' || formData.ownerCompanyID !== null)) {
    if(formData.ownerCompany && !formData.ownerCompanyID) {
      if (formData.ownerCompany !== formData.contactCompany) {
        try {
          ownerCompanyID = await createClioOwnerCompany();
          // Now call createClioOwnerPerson with the returned companyID if there is a first name value in the "Owner Contact" field and contact does not exist in Clio
          if(formData.ownerFirstName && !formData.ownerID) {
            if(formData.contactFirstName === formData.ownerFirstName && formData.contactLastName === formData.ownerLastName) {
              ownerID = contactID;
            } else {
              ownerID = await createClioOwnerPerson(ownerCompanyID);
            }
          } 
        } catch (error) {
          console.error("Error in creating contacts:", error);
        }
      } else {
        ownerCompanyID = contactCompanyID;
      }
    } else {
      // If company creation isn't needed, just create person if necessary
      if(formData.ownerFirstName && !formData.ownerID) {
        if(formData.contactFirstName === formData.ownerFirstName && formData.contactLastName === formData.ownerLastName) {
          ownerID = contactID;
        } else {
          ownerID = await createClioOwnerPerson(ownerCompanyID);
        }
      }
    }
    
    // Brief delay to wait for contactID & ownerID, if necessary
    if(!formData.contactID || !contactID || !formData.ownerID || !ownerID) {
      await wait(1000); //wait for 1 second
    }

    // if(contactID || contactCompanyID || ownerID || ownerCompanyID) {
    //   setSubmissionStatus(prevStatus => ({ ...prevStatus, createContactProfile: 'complete'}));
    // }

    if(submissionStatus.createContactProfile === 'inProcess') {
      setSubmissionStatus(prevStatus => ({ ...prevStatus, createContactProfile: 'complete'}));
    }
    
    if(formData.potentialConflicts.length === 0) {
      await setSubmissionStatus(prevStatus => ({ ...prevStatus, createContactProfile: 'complete'}));
      setSubmissionStatus(prevStatus => ({ ...prevStatus, createNewMatter: 'inProcess'}));
    }
    
    //Create new Clio matter
    try {
      matterId = await createClioMatter(ownerID, ownerCompanyID, contactID, contactCompanyID);
    } catch(error) {
      console.error("Error creating Clio matter:", error);
    }
    
    //Set status for uplodading documents, if any need to be uploaded
    if(formData.designMarkFile || formData.soundMarkFile || hasSpecimenFiles(formData)) {
      if(formData.potentialConflicts.length === 0) {
        await setSubmissionStatus(prevStatus => ({ ...prevStatus, uploadingDocuments: 'inProcess' }));
      }
    }

    //Add appropriate document storage folders to newly created matter
    if(matterId) {
      try {
        renewalFolder  = await createClioMatterFolders(matterId, formData.nextDeadlineType);
      } catch(error) {
        console.error("Error adding renewal folder to new Clio matter:", error);
      }

      try {
        carFolder  = await createClioMatterFolders(matterId, "Change of Representation");
      } catch(error) {
        console.error("Error adding CAR folder to new Clio matter:", error);
      }
      
      if(formData.applicationType === 'design') {
        try {
          logosFolder = await createClioMatterFolders(matterId, "Design Logos");
        } catch(error) {
          console.error("Error adding Design Logos folder to new Clio matter:", error);
        }
      }

      await wait(1000);
      if(formData.potentialConflicts.length === 0) {
        setSubmissionStatus(prevStatus => ({ ...prevStatus, createNewMatter: 'complete'}));
      }
    } else {
      if(formData.potentialConflicts.length === 0) {
        setSubmissionStatus(prevStatus => ({ ...prevStatus, createNewMatter: 'complete'}));
        setSubmissionStatus(prevStatus => ({ ...prevStatus, uploadingDocuments: 'complete'}));
        setIsFormSubmitted(true);
        setSubmissionStatus(prevStatus => ({ ...prevStatus, submissionComplete: 'complete'}));
        return;
      }
    }

    // Upload files to Clio
    try {
      await clioFileUpload(matterId, specimensFolder, USPTOFolder, logosFolder, pdfFile, carFile, renewalFolder, carFolder);
    } catch (error) {
        console.error('Error uploading documents to Clio:', error);
    }

    if(formData.potentialConflicts.length === 0 && (formData.designMarkFile || formData.soundMarkFile || hasSpecimenFiles(formData))) {
      setSubmissionStatus(prevStatus => ({ ...prevStatus, uploadingDocuments: 'complete'}));
    }

    if(formData.potentialConflicts.length === 0) {
      setIsFormSubmitted(true);
      setSubmissionStatus(prevStatus => ({ ...prevStatus, submissionComplete: 'complete'}));
      sendEmail('tmRenewal');
    } else {
      setIsFormSubmitted(true);
      sendEmail('tmRenewal')
      const randomInterval = Math.floor(Math.random() * (6-2+1)) + 3;
      await wait(randomInterval * 1000);
      await setSubmissionStatus(prevStatus => ({ ...prevStatus, conflictCheck: 'conflictFound'}));
    }

    reportUserEmail(formPDF, pdfFile);
  };

  const handleSignatureChange = (isSigned) => {
    setIsCARFormSigned(isSigned);
  };

  //Close submission form
  const closeSubmission = () => {
    setShowSubmissionStatus(false);

    //Reset form data
    setFormData(initialFormData);

    toggleForm();
  }
  
// Function to perform conflict check
const fetchConflictCheck = async () => {
  try {
    // Determine the search term based on form data
    let searchTerm = '';
    if (formData.wordMark.length > 0) {
      searchTerm = formData.wordMark;
    } else if (formData.markLiteralElement.length > 0) {
      searchTerm = formData.markLiteralElement;
    }

    // Only proceed if there is a search term
    if (searchTerm.length > 0) {
      const { data, error } = await supabaseConflictCheck
        .from('sow_matters')
        .select('*')
        .ilike('title', searchTerm); //search for exact match

      if (error) throw error;

      // Update the formData with potential conflicts
      setFormData(prevFormData => ({
        ...prevFormData,
        potentialConflicts: data,
      }));
      // console.log(data);
    } else {
      // Handle the case where there is no search term
      console.log("No search term provided.");
      // Optionally update the state to reflect that no data was fetched
    }
  } catch (error) {
    console.error('Error fetching conflict data for step 3:', error.message);
  }
};

  // useEffect to trigger the fetch conflict check on step 3
  useEffect(() => {
    if (currentStep === 3) {
      fetchConflictCheck();
    }
  }, [currentStep]);

const fetchClioContact = async (emailField, idField) => {
  const encodedEmail = encodeURIComponent(formData[emailField]);
  const url = `${process.env.REACT_APP_DOMAIN}/api/clio/contacts?query=${encodedEmail}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const jsonResponse = await response.json();

      if (jsonResponse.data && jsonResponse.data.length > 0) {
        let companyContact = jsonResponse.data.find(contact => contact.type === "Company");
        let personContact = jsonResponse.data.find(contact => contact.type === "Person");

        // Initialize an object to hold updates
        let updates = {};

        if(companyContact) {
          updates.contactCompanyID = companyContact.id;
        }

        if(personContact) {
          updates.contactID = personContact.id;
        }

        // Apply updates to formData if any key-value pairs exist in the updates object
        if (Object.keys(updates).length > 0) {
          setFormData(prevFormData => ({
            ...prevFormData,
            ...updates
          }));
        } else {
          // Handle case where no appropriate contacts are found
          setFormData(prevFormData => ({
            ...prevFormData,
            [idField]: null,
          }));
        }
      } else {
        // No contacts found
        setFormData(prevFormData => ({
          ...prevFormData,
          [idField]: null,
        }));
      }
    } else {
      console.error('Failed to fetch contacts from Clio.');
    }
  } catch (error) {
    console.error('Error fetching contact from Clio:', error);
  }
};

// useEffect to trigger the fetch clioContactQuery on step 2
useEffect(() => {
  const debouncedFetchContact = debounce(async () => {
    if (currentStep === 2 && formData.contactEmail) {
      // console.log("contactID:",formData.contactID);
      fetchClioContact('contactEmail', 'contactID');
    }
  }, 500);
  debouncedFetchContact();
  return () => debouncedFetchContact.cancel();
}, [currentStep, formData.contactEmail]); // Removed setFormData from the dependency array to avoid warnings

const fetchClioOwner = async (email) => {
  const encodedEmail = encodeURIComponent(email);
  const url = `${process.env.REACT_APP_DOMAIN}/api/clio/contacts?query=${encodedEmail}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const jsonResponse = await response.json();
      console.log('fetchClioOwner jsonResponse:', jsonResponse);
      if (jsonResponse.data && jsonResponse.data.length > 0) {
        let companyContact = jsonResponse.data.find(contact => contact.type === "Company");
        let personContact = jsonResponse.data.find(contact => contact.type === "Person");
        console.log('fetchClioOwner companyContact:', companyContact);
        console.log('fetchClioOwner personContact:', personContact);
        // Update formData based on whether a company or person contact is found
        setFormData(prevFormData => ({
          ...prevFormData,
          ownerID: personContact ? personContact.id : null, // Update with Person ID if found
          ownerCompanyID: companyContact ? companyContact.id : null, // Update with Company ID if found
        }));
     }
    } else {
      console.error('Failed to fetch contacts from Clio.');
    }
  } catch (error) {
    console.error('Error fetching contact from Clio:', error);
  }
};

// useEffect to trigger the fetch clioContactQuery on step 3 - check for existing owner contact
useEffect(() => {
  const debouncedFetchOwner = debounce(async () => {
    if (currentStep === 3 && formData.ownerEmail) {
      // console.log("ownerID:",formData.ownerID);
      console.log('I\'m attempting to fetch the ownerID using:', formData.ownerEmail);
    //   fetchClioOwner('ownerEmail', 'ownerID');
        fetchClioOwner(formData.ownerEmail);
    }
  }, 1500);
  debouncedFetchOwner();

  return () => debouncedFetchOwner.cancel();
}, [currentStep, formData.ownerEmail]); // Removed setFormData from the dependency array to avoid warnings

const createClioPerson = async (companyID) => {
  const contactInfo = {
    firstName: formData.contactFirstName,
    middleName: formData.contactMiddleName,
    lastName: formData.contactLastName,
    company: formData.contactCompanyID,
    companyID:companyID ? companyID : '',
    address1: formData.contactAddress1,
    address2: formData.contactAddress2,
    city: formData.contactCity,
    state: formData.contactState,
    zip: formData.contactZip,
    country: formData.contactCountry,
    email: formData.contactEmail,
  };

  const url = `${process.env.REACT_APP_DOMAIN}/api/v4/contacts/`;
  const clioContact = {
    data: {
      "addresses":[
        {
          "name":"Billing",
          "street": `${contactInfo.address1}${contactInfo.address2 ? '\n' + contactInfo.address2 : ''}`,
          "city":`${contactInfo.city}`,
          "province":`${contactInfo.state}`,
          "postal_code":`${contactInfo.zip}`,
          "country":`${contactInfo.country}`,
        }
      ],
      "clio_connect_email":`${contactInfo.email}`,
      "company": {
        "id":contactInfo.companyID ? contactInfo.companyID : ''
      },
      "email_addresses":[
        {
          "name":contactInfo.companyID ? "Work" : "Home",
          "address":`${contactInfo.email}`,
          "default_email":true,
        }
      ],
      "first_name":`${contactInfo.firstName}`,
      "last_name":`${contactInfo.lastName}`,
      "middle_name":`${contactInfo.middleName}`,
      "type":"Person",
    }
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(clioContact)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    if(data && data.data.id) {
      setFormData(prevFormData => ({ ...prevFormData, contactID: data.data.id }));
    }

    // return data & data.data.id ? data.data.id : null;
    return data.data.id;
    
  } catch (error) {
    console.error('Error creating new Clio contact:',error);
  }
};

const createClioOwnerPerson = async (ownerCompanyID) => {
  const contactInfo = {
    firstName: formData.ownerFirstName,
    middleName: formData.ownerMiddleName,
    lastName: formData.ownerLastName,
    companyID:ownerCompanyID ? ownerCompanyID : '',
    address1: formData.ownerAddress1,
    address2: formData.ownerAddress2,
    city: formData.ownerCity,
    state: formData.ownerState,
    zip: formData.ownerZip,
    country: formData.ownerCountry,
    email: formData.ownerEmail,
  };

  const url = `${process.env.REACT_APP_DOMAIN}/api/v4/contacts/`;
  const clioContact = {
    data: {
      "addresses":[
        {
          "name":"Billing",
          "street": `${contactInfo.address1}${contactInfo.address2 ? '\n' + contactInfo.address2 : ''}`,
          "city":`${contactInfo.city}`,
          "province":`${contactInfo.state}`,
          "postal_code":`${contactInfo.zip}`,
          "country":`${contactInfo.country}`,
        }
      ],
      "clio_connect_email":`${contactInfo.email}`,
      "company": {
        "id":contactInfo.companyID ? contactInfo.companyID : ''
      },
      "email_addresses":[
        {
          "name":contactInfo.companyID ? "Work" : "Home",
          "address":`${contactInfo.email}`,
          "default_email":true,
        }
      ],
      "first_name":`${contactInfo.firstName}`,
      "last_name":`${contactInfo.lastName}`,
      "middle_name":`${contactInfo.middleName}`,
      "type":"Person",
    }
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(clioContact)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    if(data && data.data.id) {
      setFormData(prevFormData => ({ ...prevFormData, contactID: data.data.id }));
    }
    
    return data && data.data.id ? data.data.id : null
  } catch (error) {
    console.error('Error creating new Clio contact:',error);
  }
};

const createClioCompany = async () => {
  const contactInfo = {
    firstName: formData.contactFirstName,
    middleName: formData.contactMiddleName,
    lastName: formData.contactLastName,
    company: formData.contactCompany,
    address1: formData.contactAddress1,
    address2: formData.contactAddress2,
    city: formData.contactCity,
    state: formData.contactState,
    zip: formData.contactZip,
    country: formData.contactCountry,
    email: formData.contactEmail,
  };

  const url = `${process.env.REACT_APP_DOMAIN}/api/v4/contacts/`;
  const clioContact = {
    data: {
      "addresses":[
        {
          "name":"Billing",
          "street": `${contactInfo.address1}${contactInfo.address2 ? '\n' + contactInfo.address2 : ''}`,
          "city":`${contactInfo.city}`,
          "province":`${contactInfo.state}`,
          "postal_code":`${contactInfo.zip}`,
          "country":`${contactInfo.country}`,
        }
      ],
      "clio_connect_email":`${contactInfo.email}`,
      "email_addresses":[
        {
          "name":contactInfo.company ? "Work" : "Home",
          "address":`${contactInfo.email}`,
          "default_email":true,
        }
      ],
      "name":`${contactInfo.company}`,
      "type":"Company",
    }
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(clioContact)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    
    if(data && data.data.id) {
      setFormData(prevFormData => ({
        ...prevFormData,
        contactCompanyID: data.data.id
      }));
    }

    return data && data.data.id? data.data.id : null;

  } catch (error) {
    console.error('Error creating new Clio company contact:',error);
  }
};

const createClioOwnerCompany = async () => {
  const contactInfo = {
    firstName: formData.ownerFirstName,
    middleName: formData.ownerMiddleName,
    lastName: formData.ownerLastName,
    company: formData.ownerCompany,
    address1: formData.ownerAddress1,
    address2: formData.ownerAddress2,
    city: formData.ownerCity,
    state: formData.ownerState,
    zip: formData.ownerZip,
    country: formData.ownerCountry,
    email: formData.ownerEmail,
  };

  const url = `${process.env.REACT_APP_DOMAIN}/api/v4/contacts/`;
  const clioContact = {
    data: {
      "addresses":[
        {
          "name":"Billing",
          "street": `${contactInfo.address1}${contactInfo.address2 ? '\n' + contactInfo.address2 : ''}`,
          "city":`${contactInfo.city}`,
          "province":`${contactInfo.state}`,
          "postal_code":`${contactInfo.zip}`,
          "country":`${contactInfo.country}`,
        }
      ],
      "clio_connect_email":`${contactInfo.email}`,
      "email_addresses":[
        {
          "name":contactInfo.company ? "Work" : "Home",
          "address":`${contactInfo.email}`,
          "default_email":true,
        }
      ],
      "name":`${contactInfo.company}`,
      "type":"Company",
    }
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(clioContact)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    
    if(data && data.data.id) {
      setFormData(prevFormData => ({
        ...prevFormData,
        ownerCompanyID: data.data.id
      }));
    }

    return data && data.data.id? data.data.id : null;

  } catch (error) {
    console.error('Error creating new Clio company contact:',error);
  }
};

const createClioMatter = async (ownerID, ownerCompanyID, contactID, contactCompanyID) => {
  let relationships = []; //Initialize relationships array

  const customFieldSetAssociations = [
    {
      "display_order":0,
      "custom_field_set": {
        "id":formData.applicationType === 'word' ? 964264 : formData.applicationType === 'design' ? 964279 : null
      }
    },
  ];

  // const firstFormattedDescription = formatDescriptions(formData.classBlocks[0].descriptions); // Apply formatting to 1st description
  const customFieldValues = [
    {
      "value": formData.supplementalRegister ? 9416284: 9416269, //Supplemental (9416284) || Principal (9416269)
      "custom_field":
        {
          "id":16055509 //Register Type
        }
    },
    {
      "value":9416764, //"Registered"
      "custom_field":
        {
          "id":16055884 //Trademark Status
        }
    },
    {
      "value":formData.teasPlus ? 9892024 : 9892039 , //TEAS Plus if "teasPlus" true, TEAS Standard if "teasPlus" is false
      "custom_field":
        {
          "id": 16962034 //TEAS Type
        }
    },
  ]

  if(formData.applicationType === 'word') {
    customFieldValues.push(
      {
        "value":9417019, //"Word Mark"
        "custom_field":
        {
          "id":16055959 //Mark Type
        }
      },
      {
        "value":`${formData.wordMark}`, //"Word Mark"
        "custom_field":
        {
          "id":16055974 //Mark
        }
      }
    )
  } else if(formData.applicationType === 'design') {
    customFieldValues.push(
      {
        "value":9417034, //"Design Logo"
        "custom_field":
        {
          "id":16055959 //Mark Type
        }
      },
      {
        "value":formData.markLiteralElement || '', //"Literal Element"
        "custom_field":
        {
          "id":16628179 //Literal Element
        }
      },
      {
        "value":`${formData.markColorClaim}`, //"Color Claim"
        "custom_field":
        {
          "id":16628194 //Color Claim
        }
      },
    )
  }

  customFieldValues.push (
    {
      "value":formData.serialNumber, 
      "custom_field":
      {
        "id":16055344 //Application Serial Number
      }
    },
    {
      "value":formData.filingDate, 
      "custom_field":
      {
        "id":16055359 //Application Filing Date
      }
    },
    {
      "value":formData.registrationNumber, 
      "custom_field":
      {
        "id":16055374 //Registration Number
      }
    },
    {
      "value":formData.registrationDate, 
      "custom_field":
      {
        "id":16055389 //Registration Date
      }
    },
  )

  if(((formData.contactID || contactID) && (formData.contactCompanyID || contactCompanyID)) && (formData.contactID || contactID) !== (formData.contactCompanyID || contactCompanyID)){
      relationships.push ({
        "description":"Contact",
        "contact":
          { "id":formData.contactID || contactID }
    });
  }

  // Determine if there's a distinct owner that is neither the contact person nor the contact company.
  const isOwnerDistinct = ((ownerID || formData.ownerID) && ![(contactID || formData.contactID), (contactCompanyID || formData.contactCompanyID)].includes((ownerID || formData.ownerID))) ||
                          ((ownerCompanyID || formData.ownerCompanyID) && ![(contactID || formData.contactID), (contactCompanyID || formData.contactCompanyID)].includes((ownerCompanyID || formData.ownerCompanyID)));

  if (isOwnerDistinct) {
    // Determine the appropriate owner ID to use (prefer the company ID over the individual's ID if both are provided and distinct).
    // const distinctOwnerID = ownerCompanyID && ownerCompanyID !== ownerID ? ownerCompanyID : ownerID;
    let distinctOwnerID = ownerCompanyID || formData.ownerCompanyID || ownerID || formData.ownerID;

    relationships.push({
      "description": "Trademark Owner",
      "contact": { "id": distinctOwnerID }
    });
  }

// Dynamically add class blocks
formData.classBlocks.forEach((block, index) => {
  customFieldSetAssociations.push({
    "custom_field_set": {
      "id": block.clioID,
    }
  });

  customFieldValues.push (
    {
      "value":block.classNo,
      "custom_field":
      {
        "id":block.clioClassID,
      }
    },
    {
      "value":block.description,
      "custom_field":
        {
          "id":block.clioDescriptionID,
        }
    },
    {
      "value": block.dateFirstUse,
      "custom_field":
      {
        "id": block.clioDateOfFirstUseID,
      }
    },
    {
      "value": block.dateFirstUseInCommerce,
      "custom_field":
      {
        "id": block.clioDateOfFirstUseInCommerceID,
      }
    },
  )

  if(block.clioURLID) {
    customFieldValues.push (
      {
        "value":block.url,
        "custom_field":
        {
          "id":block.clioURLID,
        }
      },
    )
  }
  });

  const url = `${process.env.REACT_APP_DOMAIN}/api/v4/matters/`;
  const matterInfo = {
    data: {
      "billable":true,
      "client":{ "id":formData.contactCompanyID || formData.contactID || contactCompanyID || contactID },
      "client_reference":formData.refNo ? formData.refNo : '',
      "custom_field_set_associations":customFieldSetAssociations,
      "custom_field_values":customFieldValues,
      "description":formData.wordMark || formData.markLiteralElement || 'Design Logo',
      "practice_area":{ "id":51889324 },
      "relationships": relationships
    }
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(matterInfo)
    });

    if (!response.ok) {
      await fallBackMethod();
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    try {
      const data = await response.json();
      return data && data.data.id? data.data.id : null;
    } catch {
      // console.log('Matter creation failed, moving to fallback method...');
      await fallBackMethod();
    }
    

  } catch (error) {
    console.error('Error creating new Clio matter:',error);
    // console.log('Create Matter response NOT ok! Going to fallback method');
    await fallBackMethod();
    return null;
  }
};

const createClioMatterFolders = async (matterId, folderName) => {

  const url = `${process.env.REACT_APP_DOMAIN}/api/v4/folders/`;
  const folders = {
    data: {
      "name":folderName,
      "parent":{"id":matterId, "type":"Matter"},
    }
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(folders)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    
    if(data && data.data.id) {
      setFormData(prevFormData => ({
        ...prevFormData,
        ownerCompanyID: data.data.id
      }));
    }

    return data && data.data.id? data.data.id : null;

  } catch (error) {
    console.error('Error creating folders in new Clio matter:',error);
  }
};

const clioFileUpload = async (matterId, specimensFolder, USPTOFolder, logosFolder, pdfFile, carPDF, renewalFolder,carFolder) => {

  let uploadPromises = [];

  //Upload design or sound mark file, if present
  if(formData.applicationType !== 'word' && formData.designMarkFile) {
    const designMetaData = await createClioDocument(formData.designMarkFile, logosFolder);
    uploadPromises.push(uploadClioDocument(formData.designMarkFile, designMetaData, logosFolder));
  } else if (formData.applicationType !== 'word' && formData.soundMarkFile) {
    const soundMetaData = await createClioDocument(formData.soundMarkFile, logosFolder);
    uploadPromises.push(uploadClioDocument(formData.soundMarkFile, soundMetaData, logosFolder));
  }
  
  // Upload engagement letter
  if (formData.pdfFile || pdfFile) {
    const engagementLetter = await createClioDocument(pdfFile, matterId);
    uploadPromises.push(uploadClioDocument(pdfFile, engagementLetter, matterId));
  }
  
  //Upload CAR form
  if (formData.carFile || carPDF) {
    const carForm = await createClioDocument(carPDF, carFolder);
    uploadPromises.push(uploadClioDocument(carPDF, carForm, carFolder));
  }

  //Upload certificate of registration, if available
  if(formData.certReg) {
    const certReg = await createClioDocument(formData.certReg, matterId);
    uploadPromises.push(uploadClioDocument(formData.certReg, certReg, matterId))
  }

  // Handle specimen files upload
  for (const block of formData.classBlocks) {
    if(block.specimens && block.specimens.length > 0) {
        for (const specimenFile of block.specimens) {
            const specimenMetaData = await createClioDocument(specimenFile.file, renewalFolder); 
            uploadPromises.push(uploadClioDocument(specimenFile.file, specimenMetaData, renewalFolder));
        }
    }
  }

  try {
    await Promise.all(uploadPromises);
  } catch (error) {
    await fallBackMethod();
  }

};

const createClioDocument = async (file, folderId) => {

  const url = `${process.env.REACT_APP_DOMAIN}/api/v4/documents/`;
  const fileName = file.name;
  
  const doc = {
    data: {
      "name":fileName,
      "parent":{"id":folderId, "type": fileName === "Engagement Letter.pdf" || fileName === "Certificate of Registration.pdf" ? "Matter" : "Folder" },
    }
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(doc)
    });

    if (!response.ok) {
      await fallBackMethod();
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log('createClioDocument data:', data);
    
    return data;

  } catch (error) {
    console.error('Error creating new document in Clio:',error);
    await fallBackMethod();
  }
}

const uploadClioDocument = async (file, uploadMetaData, folderId) => {

  const { put_url, put_headers } = uploadMetaData.data.latest_document_version;

  const uploadFormData = new FormData();
  uploadFormData.append('file', file);
  uploadFormData.append('folderId', folderId);
  uploadFormData.append('putUrl', put_url);
  uploadFormData.append('putHeaders', JSON.stringify(put_headers)); // Serialize put_headers before appending

  try {
      const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/upload-document`, {
          method: 'POST',
          body: uploadFormData, // No headers needed here since formData sets the correct Content-Type
      });

      if (!response.ok) throw new Error('Failed to upload document via server');

      const result = await response.json();
      console.log(result.message);
      await markUploadedDocumentComplete(uploadMetaData);
  } catch (error) {
      console.error('Error uploading document through server:', error);
  }
};

const markUploadedDocumentComplete = async (uploadMetaData) => {
  const uuid = uploadMetaData.data.latest_document_version.uuid;  
  const docId = uploadMetaData.data.id;
  const url = `${process.env.REACT_APP_DOMAIN}/api/complete-document-upload/${docId}`;

  const doc = {
    data: {
      "uuid":uuid,
      "fully_uploaded":true,
    }
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(doc)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    
    return data;

  } catch (error) {
    console.error('Error marking document as fully uploaded in Clio:',error);
  }
}

const performSearch = async (term) => {
  setLoading(true);
  const url = `${process.env.REACT_APP_DOMAIN}/api/search?searchTerm=${term}`;
  try {
      const response = await fetch(url);
      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const docs = data.docs; 
      
      // Convert the object of objects into an array of objects, then sort it
      const resultsArray = Object.keys(docs).map(key => ({
        ...docs[key],
        unique_key: `${docs[key].class_id}-${docs[key].id}` // Fix the typo here
      }));

      // Sort the results by class_id in ascending order
      const sortedResults = resultsArray.sort((a, b) => parseInt(a.class_id, 10) - parseInt(b.class_id, 10));

      // Set the sorted results
      setSearchResults(sortedResults);
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
      setLoading(false);
  }
};

//Function to format description strings including core descriptions and any accompanying additional input
const formatDescriptions = (descriptions) => {
  return descriptions.map(desc => {
    const coreDescription = desc.core; // Assuming desc.core is the core description string
    const additionalInputsStr = Object.entries(desc.additionalInputs || {})
      .map(([key, value]) => `${value}`).join(', ');
    return `${coreDescription}${additionalInputsStr ? ` ${additionalInputsStr}` : ''}`;
  }).join('; ');
};

// In RegisterTrademarkForm component
const handleTeasPlusEligibilityChange = (isEligible) => {
  // Update formData or a specific state to reflect the change in TEAS Plus eligibility
  setFormData((prevFormData) => ({
      ...prevFormData,
      teasPlus: isEligible,
  }));
};

const searchTmApplication = async (tmSearchNo) => {
    const url = `${process.env.REACT_APP_DOMAIN}/api/ts/cd/caseMultiStatus/${tmSearchNo}`;
    try {
        const response = await fetch(url);
    
        if (!response.ok) {
          setSearchError(true);
          setLoading(false);
          throw new Error(`HTTP error retrieving trademark data! status: ${response.status}`);
        }
    
        const data = await response.json();
        setSearchError(false);
        setSearchResults(data);
    } catch (error) {
        console.error('Error retrieving pending trademark application:',error);
        setSearchError(true);
        setLoading(false);
    }
};

const searchTmDocs = async (searchNo) => {
    const url = `${process.env.REACT_APP_DOMAIN}/api/tsdrapi.uspto.gov/ts/cd/casedocs/${searchNo}`;
    try {
        const response = await fetch(url);
    
        if (!response.ok) {
          throw new Error(`HTTP error retrieving trademark data! status: ${response.status}`);
        }
    
        const data = await response.text();
        // setSearchResults(data);
        await getDocumentURLs(data);

        return data;

    } catch (error) {
        console.error('Error retrieving pending trademark application:',error);
    }
};


async function fetchDocs(docUrl, docType) {
  try {
      let mimeType;
      let fileName;
      let blob;

      // Handle office actions as PDFs
      if (docType === 'certReg' && docUrl.endsWith('.pdf')) {
        // Fetch PDF document
          const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/fetch-docs`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ docUrl }),
          });

          if (!response.ok) {
              throw new Error(`Failed to fetch ${docUrl}: ${response.statusText}`);
          }

          blob = await response.blob();
          mimeType = 'application/pdf';
          fileName = 'Certificate of Registration.pdf';
      } else if (docType === 'drawing') {
          // Fetch drawing image
          const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/fetch-docs`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ docUrl }),
          });

          if (!response.ok) {
              throw new Error('Failed to fetch document');
          }

          blob = await response.blob();
          mimeType = 'image/jpeg';
          fileName = 'Logo.jpg';
      }

      // Create a File object from the blob
      if (blob) {
          const newFile = new File([blob], fileName, { type: mimeType });
          return newFile;
      }
  } catch (error) {
      console.error('Error:', error);
  }
}

function getDocumentURLs(xmlString) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "text/xml");
    
    // Find all <Document> elements
    const documents = xmlDoc.getElementsByTagName("Document");

    let mostRecentOfficeActionUrl = null;
    let mostRecentOfficeActionDate = null;
    
    // Loop through <Document> elements to find the one with <DocumentTypeCode>DRW</DocumentTypeCode>
    for (let i = 0; i < documents.length; i++) {
      const documentTypeCodeElement = documents[i].getElementsByTagName("DocumentTypeCode")[0];
      const mailRoomDateElement = documents[i].getElementsByTagName("MailRoomDate")[0];
      const urlPathElement = documents[i].getElementsByTagName("UrlPath")[0];
      
      if (!documentTypeCodeElement || !mailRoomDateElement || !urlPathElement) {
          console.log("Missing document details:", i);
          continue; // Skip this iteration if essential elements are missing
      }
      
      const documentTypeCode = documentTypeCodeElement.textContent;
      const mailRoomDate = mailRoomDateElement.textContent;
      let urlPath = urlPathElement.textContent;

        // Sanitize urlPath before using it
        if(urlPath) {
            urlPath = sanitizeUrlPath(urlPath);
        }
        
        if (documentTypeCode === "DRW") { // Check if the document type is "DRW"
            // Extract the <UrlPath> from the first "Drawing" document found
            setDrawingURL(urlPath);
        }

        // Check for office action and determine the most recent one
        if (documentTypeCode === "ORC") {
            if (!mostRecentOfficeActionDate || mailRoomDate > mostRecentOfficeActionDate) {
                mostRecentOfficeActionDate = mailRoomDate;
                mostRecentOfficeActionUrl = urlPath;
                setCertRegURL(urlPath);
            }
        }
    }
}

function sanitizeUrlPath(urlPath) {
    // Trim white spaces
    urlPath = urlPath.trim();
    // Remove trailing colon (if exists)
    urlPath = urlPath.replace(/:$/, "");
    // URL encode to ensure special characters are properly formatted

    if (urlPath.endsWith(":")) {
        urlPath.slice(0, -1); // Remove the last character (the colon)
    }

    urlPath = encodeURI(urlPath);
    return urlPath;
}

function base64ToBlob(base64, mimeType) {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], {type: mimeType});
}

// Trigger document fetches when URLs change
useEffect(() => {
    const fetchDrawing = async () => {
      if (drawingURL) {
        const drw = await fetchDocs(drawingURL, 'drawing');
        setFormData(prevFormData => ({
            ...prevFormData,
            designMarkFile: drw,
        }));
      }
    };
  
    fetchDrawing();
  }, [drawingURL]); // Depend on drawingURL
  
  useEffect(() => {
    const fetchCertReg = async () => {
      if (certRegURL) {
        const certReg = await fetchDocs(certRegURL, 'certReg');
        setFormData(prevFormData => ({
            ...prevFormData,
            certReg: certReg,
        }));
      }
    };
  
    fetchCertReg();
  }, [certRegURL]); // Depend on certRegURL

    useEffect(() => {
        // Ensure formData.officeAction is not null or undefined
        if (formData.certReg instanceof File) {
            console.log(formData.certReg);
        }
    }, [formData.certReg]);

  // Handler to update validation state for the current step
  const handleValidationChange = (isValid) => {
    setStepIsValid(prevState => ({
        ...prevState,
        [currentStep]: isValid
    }));
  };

  //fallback method for retaining user information. In case of failure to send to Clio, send information to supabase 'fallBack' bucket
const fallBackMethod = async () => {
  sendEmail('failed');

  // console.log('Attempting fallback method!');
  const url = `${process.env.REACT_APP_DOMAIN}/api/upload`;
  const data = new FormData();

  //Append the existing formData as a JSON string
  data.append('formData', JSON.stringify(formData));

  // Append files from designMarkFile and soundMarkFile if they exist
  if (formData.designMarkFile) { // Assuming designMarkFile is a File object
    data.append('designMarkFile', formData.designMarkFile);
  }

  if (formData.soundMarkFile) { // Assuming soundMarkFile is a File object
    data.append('soundMarkFile', formData.soundMarkFile);
  }

  // Append specimen files if they exist
  formData.classBlocks.forEach(block => {
    if (block.specimens) {
      block.specimens.forEach(specimen => {
        if (specimen.file) { // Assuming `file` is the file object in each specimen
          data.append('specimens', specimen.file);
        }
      });
    }
  });

  try {
    const response = await fetch(url, {
      method: 'POST',
      body: data,
    });

    if (response.ok) {
      const result = await response.json();
      // console.log('Success:', result);
    } else {
      throw new Error('Network response was not ok.');
    }

  } catch (error) {
    console.error('Error:', error);
    // Handle errors here, e.g., alert or set state
  }
};

const sendEmail = async (submissionType) => {
  const subjectLines = {
    tmApplication: 'New Trademark Application Requested',
    tmOAR: 'Trademark Office Action Response Requested',
    tmSearch: 'Trademark Search Requested',
    tmRenewal: 'Trademark Renewal Requested',
    tmInternational: 'International Trademark Requested',
    failed: 'FAILED New Trademark Application Requested',
  };

  let emailSubject;
  if (formData.potentialConflicts.length === 0) {
    emailSubject = subjectLines[submissionType] || 'San Novus Trademark Inquiry';
  } else {
    emailSubject = `POTENTIAL CONFLICT FOUND | ${subjectLines[submissionType]}`;
  }

  const msgType = 'newTM';
  const emailHtml = createEmailHtml(formData); // Generate HTML on the front-end

  try {
    const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/send-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ emailHtml, emailSubject, msgType }),
    });
    const result = await response.json();
  } catch (error) {
    console.error('Error sending email:', error);
  }
};

// Function to create email body for notification about new website submission
const createEmailHtml = (formData) => {
  const markType = formData.wordMark ? 'word mark' : formData.designMarkFile ? 'design logo' : 'sound mark';
  const mark = formData.wordMark || (formData.designMarkFile ? (formData.markLiteralElement || 'design logo') : 'sound mark');

  return `
    <html>
    <head>
      <style>
        body { font-family: Times-New-Roman, serif; }
        h2 { color: #333; }
        p { margin: 0; }
        ul { padding-left: 20px; }
        li { margin-bottom: 5px; }
      </style>
    </head>
    <body>
      <h2><u>Potential Conflicts:</u></h2>
      <ul>
        ${formData.potentialConflicts && formData.potentialConflicts.length > 0 ? 
          formData.potentialConflicts.map(potentialConflict => `
          <li>
            Matter Code: ${potentialConflict.mat_code}<br>
            Title: ${potentialConflict.title}
          </li>`).join('') : 
          '<li>None</li>'
        }
      </ul>
      <br>
      <h2><u>Contact Information:</u></h2>
      <p>First Name: ${formData.contactFirstName}</p>
      <p>Middle Name: ${formData.contactMiddleName}</p>
      <p>Last Name: ${formData.contactLastName}</p>
      <p>Company Name: ${formData.contactCompany}</p>
      <p>Address 1: ${formData.contactAddress1}</p>
      <p>Address 2: ${formData.contactAddress2}</p>
      <p>City: ${formData.contactCity}</p>
      <p>State: ${formData.contactState}</p>
      <p>Zip: ${formData.contactZip}</p>
      <p>Country: ${formData.contactCountry}</p>
      <p>Email: ${formData.contactEmail}</p>
      <p>Alt Reference: ${formData.refNo || ''}</p>

      <br>

      <h2><u>Owner Information:</u></h2>
      <p>First Name: ${formData.ownerFirstName}</p>
      <p>Middle Name: ${formData.ownerMiddleName}</p>
      <p>Last Name: ${formData.ownerLastName}</p>
      <p>Company Name: ${formData.ownerCompany}</p>
      <p>Address 1: ${formData.ownerAddress1}</p>
      <p>Address 2: ${formData.ownerAddress2}</p>
      <p>City: ${formData.ownerCity}</p>
      <p>State: ${formData.ownerState}</p>
      <p>Zip: ${formData.ownerZip}</p>
      <p>Country: ${formData.ownerCountry}</p>
      <p>Email: ${formData.ownerEmail}</p>

      <br>

      <h2><u>Trademark Information:</u></h2>
      <p>Type: ${markType}</p>
      <p>Mark: ${mark}</p>
      <ul>
        ${formData.classBlocks && formData.classBlocks.length > 0 ? 
          formData.classBlocks.map(block => `
          <li>
            International Class: ${block.classNo}<br>
            Description: ${block.descriptions ? block.descriptions.map(desc => desc.core).join(', ') : block.description}
          </li>`).join('') : 
          '<li>No class information available.</li>'
        }
      </ul>
    </body>
    </html>
  `;
};

const reportUserEmail = async (formPDF, pdfFile) => {
  const emailHtml = createClientEmail(formData,'a trademark renewal'); // Generate HTML on the front-end

  const emailData = new FormData();
  emailData.append('emailHtml', emailHtml);
  emailData.append('formPDF', formPDF);
  emailData.append('pdfFile', pdfFile);
  emailData.append('recipient', formData.contactEmail);

  // // Calculate and log the size of the FormData
  // const formDataSize = await calculateFormDataSize(emailData);
  // console.log('emailData size:', formDataSize, 'bytes');

  try {
    const response = await fetch(`${process.env.REACT_APP_DOMAIN}/api/send-confirmation-email`, {
      method: 'POST',
      body: emailData,
    });
    const result = await response.json();
  } catch (error) {
    console.error('Error sending email:', error);
  }
};

const calculateFormDataSize = async (formData) => {
  const formDataEntries = Array.from(formData.entries());
  const body = new Blob(formDataEntries.map(([key, value]) => {
    if (value instanceof Blob) {
      return new Uint8Array([key.length, ...key.split('').map(c => c.charCodeAt(0)), ...new Uint8Array(value.size)]);
    } else {
      return new Uint8Array([key.length, ...key.split('').map(c => c.charCodeAt(0)), ...new Uint8Array(value.length)]);
    }
  }));
  return body.size;
};



// Trigger PDF generation and download
const generateEngagementPDF = async () => {

  const url = `${process.env.REACT_APP_DOMAIN}/api/generate-pdf`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ 
      html: engagementContent, 
      signature: {
        text: signatureText,
        font: "Lucida Handwriting"
      }, 
      dateTime: signatureTime 
    }),
  });

  if (!response.ok) {
    throw new Error('Failed to generate PDF');
  }

  const pdfBuffer = await response.arrayBuffer();

  // Convert PDF buffer to File and save it to formData
  const pdfFile = new File([pdfBuffer], 'Engagement Letter.pdf', { type: 'application/pdf' });

  // return await response.arrayBuffer();
  return pdfFile;
};

// Trigger PDF generation and download
const generateCarPDF = async () => {

  const url = `${process.env.REACT_APP_DOMAIN}/api/generate-car-pdf`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ 
      html: carContent, 
      signature: {
        text: '',
        font: "Lucida Handwriting"
      }, 
      dateTime: '', 
    }),
  });

  if (!response.ok) {
    throw new Error('Failed to generate PDF');
  }

  const pdfBuffer = await response.arrayBuffer();

  // Convert PDF buffer to File and save it to formData
  const carFile = new File([pdfBuffer], 'CAR.pdf', { type: 'application/pdf' });

  // return await response.arrayBuffer();
  return carFile;
};

// Trigger PDF generation and download
const generateFormPDF = async (htmlContent) => {

  const url = `${process.env.REACT_APP_DOMAIN}/api/generate-form-pdf`;

  // Convert the designMarkFile to a base64 string
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  let base64DesignMark;

  if(formData.applicationType !== 'word' && formData.designMarkFile) {
    base64DesignMark = await getBase64(formData.designMarkFile);
  }
  
  console.log('base64DesignMark:', base64DesignMark);

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ 
      html: htmlContent, 
      designMarkFile: base64DesignMark,
      signature: {
        text: '',
        font: "Lucida Handwriting"
      }, 
      dateTime: '', 
    }),
  });

  if (!response.ok) {
    throw new Error('Failed to generate PDF');
  }

  const pdfBuffer = await response.arrayBuffer();

  // Convert PDF buffer to File and save it to formData
  const formPDF = new File([pdfBuffer], 'Submission Information.pdf', { type: 'application/pdf' });

  // return await response.arrayBuffer();
  return formPDF;
};
 
  return (
    <div className="test-modal">
      <StepIndicator currentStep={currentStep} totalSteps={5}/>
      {currentStep === 1 && <div>
        <h1>Contact Information:</h1>
        <ContactContact  
          formData={formData} 
          onFormDataChange={handleFormDataChange} 
          onTransferDataChange={setTransferData} 
          onValidationChange={handleValidationChange}
          attemptedSubmit={attemptedSubmit}
        /></div>}
      {currentStep === 2 && <div>
        <div>
          <h1>Trademark Registration</h1>
          <h2>Registered Trademark Search</h2>
        </div>
        {validationError && <div className="alert alert-danger">{validationError}</div>}
        <RegRetrival
            formData={formData}
            searchTmApplication={searchTmApplication}
            searchResults={searchResults}
            setFormData={setFormData}
            searchTmDocs ={searchTmDocs}
            certRegURL ={certRegURL}
            drawingURL={drawingURL}
            onValidationChange={handleValidationChange}
            attemptedSubmit={attemptedSubmit}
            searchError = {searchError}
            setValidationError={setValidationError}
        />
        {validationError && <div className="alert alert-danger">{validationError}</div>}
        </div>}
        {currentStep === 3 && <div>
        <h1>Usage Information:</h1>
        <RenewalUseInfo 
          formData={formData}
          classBlocks={formData.classBlocks} 
          onUpdateClassBlocks={onUpdateClassBlocks}
          onUpdateSpecimens={onUpdateClassBlocks}
          onFormDataChange={handleFormDataChange}
          setFormData={setFormData}
          onDeleteSpecimens={onDeleteSpecimens}
          onValidationChange={handleValidationChange}
          attemptedSubmit={attemptedSubmit}
        />
        </div>}
        {currentStep === 4 && <div>
        <div>
          <h1>Renewal Review</h1>
        </div>
        <RegReview 
          formData={formData}
          estimatedCost={estimatedCost}
          setEstimatedCost={setEstimatedCost}
        />
        </div>}
      {currentStep === 5 && <div>
        <div>
            <h1>Change of Representation</h1>
        </div>
        <CAR
          formData={formData}
          drawingURL={drawingURL}
          setCarContent={setCarContent}
          onSignatureChange={handleSignatureChange}
        />
        </div>}
      
      <div>
        <button onClick={previousStep} disabled={currentStep === 1} className='form-buttons'>Back</button>
        {currentStep < 5 && <button onClick={nextStep} disabled={currentStep > 4 } className='form-buttons'>Next</button>}
        <div>
          <button className='cancel-button' onClick={() => toggleForm(false)}>Cancel</button>
        </div>
        {/* <button onClick={nextStep} disabled={currentStep > 3 } className='form-buttons'>Next</button> */}
        {currentStep === 5 && <button className='form-buttons' onClick={submitForm} disabled={!isCARFormSigned}>Submit</button>}
        {showEngagementLetter && (
          <EngagementLetterRenewal
            formData={formData}  
            showEngagementLetter={showEngagementLetter}
            onClose={() => setShowEngagementLetter(false)}
            onSign={() => {
              setIsEngagementLetterSigned(true);
              setShowEngagementLetter(false);
            }}
            onAgree={handleAgree}
            setEngagementContent={setEngagementContent}
            setSignatureText={setSignatureText}
            setSignatureTime={setSignatureTime}
            estimatedCost={estimatedCost}
          />
        )}
        {showSubmissionStatus && (
          <div className='submission-form'>
            <SubmissionStatus 
              status={submissionStatus}
              hasUploadedDocuments={hasUploadedDocuments()}
              closeSubmission = {closeSubmission}
              formData={formData}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RenewTm;
